import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { kukki } from '@owner/api/cookiesToken';
import { apiNoPrefix } from '@owner/api';
import { encryptTracker } from './etc.js';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getLocalDateInIso8601Format = () =>
  dayjs().format('YYYY-MM-DDTHH:mm:ssZ');

export const deviceChecker = () => {
  const { userAgent } = navigator;
  const isItMobileDevice = /Mobi/g.test(userAgent);

  return isItMobileDevice ? 'mobile' : 'desktop';
};

const updateLastActivity = ({ isHidden }) => {
  if (isHidden) {
    localStorage.setItem('last-activity', new Date().getTime());
  } else {
    localStorage.removeItem('last-activity');
  }
};

/* If the user click another tab on the browser,
 * write 'last-activity' with current time it's value into localStorage.
 * And if the user come back to this page (tab), remove the 'last-activity'.
 */
const manageLastActivity = () => {
  const PREFIX_HIDDEN = [
    {
      var: 'hidden',
      event: 'visibilitychange',
    },
    {
      var: 'mozHidden',
      event: 'mozvisibilitychange',
    },
    {
      var: 'webkitHidden',
      event: 'webkitvisibilitychange',
    },
    {
      var: 'msHidden',
      event: 'msvisibilitychange',
    },
  ];

  PREFIX_HIDDEN.forEach(i => {
    if (i.var in document) {
      document.addEventListener(i.event, () =>
        updateLastActivity({ isHidden: document[i.var], event: i.event })
      );
    }
  });

  window.onpageshow = () => {
    updateLastActivity({ isHidden: false });
  };
  window.onfocus = () => {
    updateLastActivity({ isHidden: false });
  };
  document.focusin = () => {
    updateLastActivity({ isHidden: false });
  };

  window.onpagehide = () => {
    updateLastActivity({ isHidden: true });
  };
  window.onblur = () => {
    updateLastActivity({ isHidden: true });
  };
  document.focusout = () => {
    updateLastActivity({ isHidden: true });
  };
};

/* Compare 'last-activity' date value in localStorage and current time.
 * If the value is equal or less than 3 hours,
 * we can consider the user still in session
 */
const isSessionValid = () => {
  const lastActivity = localStorage.getItem('last-activity') || Date.now();
  const now = Date.now();
  const diffHour = Math.abs(
    Math.round((now - lastActivity) / 1000 / (60 * 60))
  );

  return diffHour <= 3;
};

const getTrackerSesionOwner = async () => {
  let trackerSession = localStorage.getItem('tracker-session');
  const isLoggedIn = kukki.getToken('access');
  const isGeneratedFromServer = trackerSession && trackerSession.length <= 12;

  /* If there's 'tracker-session' in local storage
   * AND still less than 3 hours from last activity
   * AND (currently logged in and the tracker value is from server
   * OR is not logged in and tracker is uuid)
   * return the existing current value in 'tracker-session'.
   * In other words, the 'tracker-session' is exist on local storage.
   */
  if (
    trackerSession &&
    isSessionValid() &&
    ((isLoggedIn && isGeneratedFromServer) ||
      (!isLoggedIn && !isGeneratedFromServer))
  ) {
    return trackerSession;
  }

  if (isLoggedIn) {
    const res = await apiNoPrefix.get('/pms_api/v3/tracker-session/active');
    trackerSession = res?.data['tracker-session']?.session_id || '';
  } else {
    trackerSession = uuidv4();
  }

  localStorage.setItem('tracker-session', trackerSession);
  return trackerSession;
};

const sendEventToInternalLogger = async (trackerName, trackerValue) => {
  const userId = trackerValue.owner_id ? trackerValue.owner_id : null;

  const deviceId =
    Cookies?.get('USER_DATA')
      ? JSON.parse(Cookies.get('USER_DATA')).deviceUuid
      : null;

  const sessionId = await getTrackerSesionOwner();

  const trackingData = JSON.stringify({
    user: {
      user_id: userId,
      device_id: deviceId,
      session_id: sessionId,
      platform: window.innerWidth < 768 ? 'Web Mobile' : 'Web Desktop',
    },
    e: trackerName,
    event_time: getLocalDateInIso8601Format(),
    a: trackerValue,
    URL: window.location.href,
  });

  const apiLoggerUrl = `${process.env.VUE_APP_API_LOGGER_URL}`;

  axios({
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      'x-api-key': '6QizyeWpdD3LXAs9f4CwE1zPrTY7EbNr4DVEGp82',
    },
    url: apiLoggerUrl,
    data: {
      param: encryptTracker(trackingData),
    },
  }).catch(error => {
    console.error(error);
  });
};

const trackThisEvent = (trackerName, trackerValue) => {
  if (window.Moengage) {
    window.Moengage.track_event(trackerName, trackerValue);
  }

  if (process.env.VUE_APP_API_LOGGER_URL) {
    sendEventToInternalLogger(trackerName, trackerValue);
  }
};

const tracker = (trackerName, trackerValue) => {
  try {
    trackThisEvent(trackerName, {
      ...trackerValue,
      interface: trackerValue.interface || deviceChecker(),
    });
  } catch (error) {
    console.error(error);
  }
};

// Expose tracker function to global
window.manageLastActivity = manageLastActivity;
window.tracker = tracker;

window.onload = () => {
  window.manageLastActivity();
};

const VuePlugin = {
  install(VueConstructor) {
    VueConstructor.prototype.$tracker = (trackerName, trackerValue) => {
      tracker(trackerName, trackerValue);
    };
  },
};

export default VuePlugin;
